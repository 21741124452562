import { isNotNil, LeftyFormat } from '@frontend2/core';
import { CurrencyEnum, Network } from '@frontend2/proto/common/proto/common_pb';
import { GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances } from '@frontend2/proto/librarian/proto/casting_pb';
import {
  createCell,
  createRow,
} from '../lefty-data-table/lefty-data-table.helpers';
import { Cell, Row } from '../lefty-data-table/lefty-data-table.models';

export interface ReportPerformances {
  readonly tableRows: Row<GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances>[];
}

export function createReportPerformances(
  partial?: ReportPerformances,
): ReportPerformances {
  return {
    tableRows: partial?.tableRows ?? [],
  };
}

export function isGhostPerformance(
  performance: GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances,
): boolean {
  return performance.network === Network.NETWORK_UNKNOWN;
}

export function buildReportPerformancesRow(
  data: GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances,
  currency?: CurrencyEnum,
): Row<GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances> {
  const rowId = isNotNil(data.network) ? data.network.toString() : '-';
  const rows = createRow(rowId, {
    data,
    isGhost: isGhostPerformance(data),
    cells: [
      createNetworkNameCell(data),
      createLikesCell(data),
      createCommentCell(data),
      createImpressionsCell(data),
      createEmvCell(data, currency ?? CurrencyEnum.NA),
      createEngagementRateCell(data),
      createEstimatedCostsCell(data, currency ?? CurrencyEnum.NA),
      createEstimatedRoiCell(data),
      createEstimatedCpmCell(data),
      createEstimatedCpeCell(data),
    ],
  });
  return rows;
}

export function createNetworkNameCell(
  data: GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances,
): Cell<unknown> {
  return createCell({ data: data.network });
}

export function createLikesCell(
  data: GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances,
): Cell<unknown> {
  return createCell({
    formattedValue: LeftyFormat.likes(data.likes),
  });
}

export function createCommentCell(
  data: GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances,
): Cell<unknown> {
  return createCell({
    formattedValue: LeftyFormat.comments(data.comments),
  });
}

export function createImpressionsCell(
  data: GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances,
): Cell<unknown> {
  return createCell({
    formattedValue: LeftyFormat.impressions(data.impressions),
  });
}

export function createEmvCell(
  data: GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances,
  currency: CurrencyEnum,
): Cell<unknown> {
  return createCell({
    formattedValue: LeftyFormat.emv(data.emv, {
      currency,
    }),
  });
}

export function createEngagementRateCell(
  data: GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances,
): Cell<unknown> {
  return createCell({
    formattedValue: LeftyFormat.engagementRate(data.engagementRate),
  });
}

export function createEstimatedCostsCell(
  data: GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances,
  currency: CurrencyEnum,
): Cell<unknown> {
  return createCell({
    formattedValue: LeftyFormat.currency(data.costs ?? 0, {
      currency,
    }),
  });
}

export function createEstimatedRoiCell(
  data: GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances,
): Cell<unknown> {
  return createCell({
    formattedValue: LeftyFormat.roi(data.roi ?? BigInt(0), { showZero: false }),
  });
}

export function createEstimatedCpmCell(
  data: GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances,
): Cell<unknown> {
  return createCell({
    formattedValue: LeftyFormat.cpm(data.calculatedCpm ?? BigInt(0), {
      showZero: false,
    }),
  });
}

export function createEstimatedCpeCell(
  data: GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances,
): Cell<unknown> {
  return createCell({
    data: data.calculatedCpe,
  });
}

export function createTotalRow(
  data: GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances[],
): GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances {
  const sumBigInt = (arr: bigint[]): bigint =>
    arr.reduce((total, value) => total + value, BigInt(0));
  return new GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances(
    {
      likes: sumBigInt(data.map((x) => BigInt(x.likes))),
      comments: sumBigInt(data.map((x) => BigInt(x.comments))),
      impressions: sumBigInt(data.map((x) => BigInt(x.impressions))),
      emv: sumBigInt(data.map((x) => BigInt(x.emv))),
      engagementRate: 0,
      costs: sumBigInt(data.map((x) => BigInt(x.costs))),
      roi: 0,
      calculatedCpm: 0,
      calculatedCpe: 0,
    },
  );
}
