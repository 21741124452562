import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  input,
  Input,
  NgZone,
  Signal,
  ViewChild,
} from '@angular/core';

import { LeftyColors, LeftyFormat } from '@frontend2/core';
import { generateBarChart } from '../charts/bar/bar.helpers';
import { DataSet } from '../charts/charts.models';
import { LeftyCardComponent } from '../lefty-card/lefty-card.component';
import { LeftyComponent } from '../utils';
import { ChartWithDataSet } from './lefty-age-range.helpers';

@Component({
  selector: 'lefty-age-range',
  templateUrl: 'lefty-age-range.component.html',
  styleUrls: ['lefty-age-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyCardComponent],
})
export class LeftyAgeRangeComponent
  extends LeftyComponent
  implements AfterViewInit
{
  constructor(private zone: NgZone) {
    super();
  }
  DEFAULT_BAR_WIDTH = 10;
  readonly displayChartName = input(true);
  readonly barWidth = input(this.DEFAULT_BAR_WIDTH);
  readonly femalePercentage = input(0);

  @Input()
  card: ChartWithDataSet = {
    type: 'age_range',
    title: $localize`Age Range`,
    data: [],
  };

  @ViewChild('bar')
  barElRef?: ElementRef<HTMLDivElement>;

  categories: string[] = [];
  chartDataSet: Signal<DataSet[]> = computed(() => [
    {
      name: $localize`Female ${this.femalePercentage() !== 0 ? ': ' + LeftyFormat.percent(this.femalePercentage() / 100) : ''}`,
      values: [],
      color: LeftyColors.teal300,
    },
    {
      name: $localize`Male ${this.femalePercentage() !== 0 ? ': ' + LeftyFormat.percent((100 - this.femalePercentage()) / 100, { showZero: false }) : ''}`,
      values: [],
      color: LeftyColors.teal800,
    },
  ]);

  generateBar(): void {
    this.zone.runOutsideAngular(() => {
      if (this.barElRef) {
        generateBarChart(this.barElRef.nativeElement, this.chartDataSet(), {
          size: { height: 200 },
          withGrid: false,
          barPadding: 10,
          yFormatter: this.formatter,
          categories: this.categories,
          barWidth: this.barWidth(),
          withLabels: true,
          config: {
            radius: 2,
          },
        });
      }
    });
  }

  formatter(value: number | Date | null): string {
    if (value === null || value instanceof Date) {
      return '';
    }
    return LeftyFormat.percent(value, {
      compact: true,
      maximumFractionDigits: 1,
    });
  }

  createChartDataSet(): void {
    for (const d of this.card.data) {
      this.categories.push(d.name);
      this.chartDataSet()[1].values.push(d.values[0]);
      this.chartDataSet()[0].values.push(d.values[1]);
    }
  }

  get chartText(): string {
    return $localize`Age of followers`;
  }

  ngAfterViewInit(): void {
    this.createChartDataSet();
    this.generateBar();
  }
}
