import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  Input,
  input,
} from '@angular/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { readableNetwork } from '../followers-range-dropdown/followers-range-dropdown.helpers';
import { NetworkIconComponent } from '../icon/network.component';
import { LeftyComponent } from '../utils';

@Component({
  selector: 'network-with-name',
  templateUrl: './network-with-name.component.html',
  styleUrls: ['./network-with-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NetworkIconComponent],
})
export class NetworkWithNameComponent extends LeftyComponent {
  readonly network = input(Network.NETWORK_UNKNOWN);
  readonly name = computed(() => readableNetwork(this.network()));

  @HostBinding('class.table-head')
  @Input()
  isHeader = false;
}
