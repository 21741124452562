<lefty-card
  [withBorder]="false"
  [withPadding]="true"
  class="audience-card"
>
  <h4 class="card-heading">{{ card.title }}</h4>
  <div class="chart-container">
    <div class="legends flex">
      @for (d of chartDataSet(); track $index) {
        <div class="body-1 legend">
          <div
            class="dot"
            [style.background-color]="d.color"
          ></div>
          {{ d.name }}
        </div>
      }
    </div>

    <div #bar></div>
    @if (displayChartName()) {
      <div class="body-1 chartText">{{ chartText }}</div>
    }
  </div>
</lefty-card>
