import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';

import { NgIf } from '@angular/common';
import { isNotEmptyArray, isNotNil } from '@frontend2/core';
import { GenerikAudienceData } from '@frontend2/proto/librarian/proto/audience_pb';
import { LeftyAgeRangeComponent } from '../../lefty-age-range/lefty-age-range.component';
import {
  ChartWithDataSet,
  createAgeBarDateSet,
} from '../../lefty-age-range/lefty-age-range.helpers';
import { LeftyComponent } from '../../utils';

@Component({
  selector: 'report-audience-age',
  templateUrl: 'report-audience-age.route.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['report-audience-age.route.component.scss'],
  standalone: true,
  imports: [NgIf, LeftyAgeRangeComponent],
})
export class ReportAudienceAgeComponent extends LeftyComponent {
  DEFAULT_BAR_WIDTH = 10;
  BAR_WIDTH = 24;
  readonly barWidth = computed(() =>
    !this.isMobileLayout() ? this.BAR_WIDTH : this.DEFAULT_BAR_WIDTH,
  );

  readonly card = computed(() => this.ageRange());
  readonly isNotEmptyState = computed(() => isNotEmptyArray(this.card().data));
  readonly isMobileLayout = input(false);

  readonly report = input(new GenerikAudienceData());
  ageRange(): ChartWithDataSet {
    return {
      type: 'age_range',
      title: $localize`Age & Gender`,
      data:
        createAgeBarDateSet(
          this.report().agesReportMale,
          this.report().agesReportFemale,
        )?.filter(isNotNil) ?? [],
    } as ChartWithDataSet;
  }
}
