import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LeftyFormat } from '@frontend2/core';
import { LeftyTooltipDirective } from '../../lefty-tooltip/lefty-tooltip.directive';
import { CpeCellRenderer } from './cpe-cell-helpers';

@Component({
  selector: 'cpe-cell',
  templateUrl: './cpe-cell.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyTooltipDirective],
})
export class CpeCellComponent extends CpeCellRenderer {
  value = '-';
  override render(newValue: number): void {
    this.value = LeftyFormat.cpe(
      newValue ?? BigInt(0),
      { showZero: false },
      true,
    );
  }

  cpeTooltip(): string {
    return LeftyFormat.cpe(
      this.estimatedCpe ?? BigInt(0),
      { showZero: false },
      false,
    );
  }

  hasTooltip(): boolean {
    return this.estimatedCpe > 0 && this.estimatedCpe < 0.01;
  }
}
