import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { API_HOST, GrpcModule } from '@frontend2/api';

import { LeftyErrorHandlerModule, UiModule } from '@frontend2/ui';
import { AppComponent } from './app.component';

import { environment } from '../environments/environment';
import { NoAccessRouteComponent } from './no-access/no-access.route.component';
import { AppRouterModule } from './router.module';

@NgModule({
  declarations: [AppComponent, NoAccessRouteComponent],
  imports: [
    BrowserModule,
    UiModule,
    AppRouterModule,
    LeftyErrorHandlerModule,
    GrpcModule,
  ],
  providers: [{ provide: API_HOST, useValue: environment.apiHost }],
  bootstrap: [AppComponent],
})
export class AppModule {}
